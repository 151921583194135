.burger-menu {
	$this: &;

	display: none;

	@include media('1100', max) {
		display: block;
		position: fixed;
		top: 0;
		left: -100%;
		z-index: 102;
		width: 100%;
		height: 100%;
		padding: 100px var(--container-padding) 50px;
		@include flex-column;
		background-color: var(--primary);
		color: var(--white);
		transition: var(--transition) left;
		overflow-y: auto;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 20px;
		background-color: transparent;
		border: none;
		font-size: 45px;
		line-height: 57%;
		color: var(--white);
	}

	&__nav {
		.nav-menu {
			&__list {
				flex-direction: column;
			}

			&__link {
				font-size: 24px;

				@include media('767', max) {
					font-size: 20px;
				}
			}
		}
	}

	&__menu {
		flex-direction: column;
		align-items: center;
		flex-wrap: nowrap;

		.menu-header {
			&__link {
				display: flex;
				font-size: 24px;
				font-weight: 600;
				color: var(--white);

				@include media('767', max) {
					font-size: 20px;
				}
			}
		}
	}

	&.active {
		@include media('1100', max) {
			left: 0;

			#{$this}__close {
				position: fixed;
			}
		}
	}
}
