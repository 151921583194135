.contacts {
    &__row {
        display: grid;
        gap: 20px;

        @include media('1101', min) {
            grid-template-columns: 0.47fr 0.53fr;
        }
    }

    &__content {
        padding-bottom: 0;

        p {
            color: #46433C;

            b {
                font-weight: 500;
            }
        }
    }
}

.map {
    @include media('1100', max) {
        height: 450px;
    }

    @include media('500', max) {
        height: 300px;
    }

    @include media('1101', min) {
        width: 100%;
        height: 100%;
    }

    >* {
        width: 100% !important;
        height: 100% !important;

        >* {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.ballon-content {
    max-width: 298px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 25px;
    font-family: var(--main-font);
    color: var(--black);

    &__title {
        font-weight: 600;
    }

    &__address {
        font-weight: 400;
        color: var(--black);
    }
}