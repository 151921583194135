.header {
	$this: &;

	&--absolute {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 101;
    }

    @include media('1100', max) {
        @include padding-y(10px);
    }

    &__inner {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        
        @include media('1101', min) {
            grid-template-areas:
            'logo top'
            'logo menus'
            ;
            grid-template-rows: repeat(2, max-content);
        }
    }

    &__logo {
        @include media('1100', max) {
            max-width: 100px;
        }

        @include media('1101', min) {
            grid-area: logo;
        }
    }

    &__top {
        @include flex-center-between;
        flex-wrap: wrap;
        gap: 10px 40px;

        @include media('1100', max) {
            flex-grow: 1;
            justify-content: flex-end;
            column-gap: 20px;
        }

        @include media('1101', min) {
            padding-bottom: 10px;
            border-bottom: 1px solid var(--primary);
            grid-area: top;
        }
    }

    &__menus {
        grid-area: menus;
        @include flex-center-between;
    }

    & &__nav,
    & &__menu {
        @include media('1100', max) {
            display: none;
        }
    }

    &__burger {
        position: relative;
        flex-shrink: 0;
    }

    &__schedule {
        position: relative;
        padding-left: 8px;
		font-size: 12px;
        font-weight: 500;
        line-height: 1.2;
        color: var(--gray);

        &::before {
            content: '';
            @include position-center-y;
            left: 0;
            @include round(4px, var(--secondary));
        }

        @include media('767', max) {
            font-size: 10px;
        }
	}

	// .header__contacts

	&__contacts {
		@include flex-column-end;
        line-height: 1.2;
        font-weight: 500;
        
        @include media('1101', min) {
            margin-left: auto;
        }
	}

    &__contacts-wrap {
        @include flex-center-between;
        gap: 15px;
        flex-grow: 1;

        @include media('500', max) {
            justify-content: flex-end;
        }
    }

	&__phone {
        span {
            font-size: 20px;

            @include media('767', max) {
                font-size: 14px;
            }

            @include media('500', max) {
                font-size: 12px;
            }
        }

        @include media('767', max) {
            font-size: 12px;
        }

        @include media('500', max) {
            font-size: 10px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: var(--primary);
            }   
        }
	}

	&__search {
        position: relative;

        &-btn {
            flex-shrink: 0;
        }
	}

    &__actions {
        @include flex-center-y;
        gap: 25px;
    }

    &__action {
        position: relative;
        @include flex-center-y;

        .icon {
            stroke: var(--primary)
        }

        span {
            position: absolute;
            top: -8px;
            right: -8px;
            padding: 1px 5px;
            font-size: 14px;
            line-height: 1.2;
            font-weight: 500;
            border-radius: 2px;
            background-color: var(--primary);
            color: var(--white);
        }

        &.burger-btn {
            @include media('1101', min) {
                display: none;
            }
        }
    }
}

.header-social {
    @include flex-center-y;
    gap: 15px;

    &__link {
        @include flex-center-y;
    }

    &__icon {
        stroke: var(--primary);

        &--inst {
            @include media('1101', min) {
                width: 36px;
                height: 36px;
            }
        }
    }
}