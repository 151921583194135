.form {
    $this: &;

	@include flex-column;
	gap: 20px;

	&__fields {
		display: grid;
		gap: var(--gap);

		@include media('767', max) {
			gap: 20px;
		}

		@include media('768', min) {
			grid-template-columns: repeat(2, 1fr);
		}
		
		&--cart {
			gap: 20px;
		}
	}

    &__bottom {
        display: grid;
        gap: 15px;

		@include media('768', min) {
			grid-template-columns: 1fr auto;
			grid-template-areas:
			'agree btn'
			'required btn'
			;
			align-items: center;
		}
    }

	&__agree,
	&__required {
		font-size: 14px;

		@include media('500', max) {
			font-size: 12px;
		}
	}

	&__agree {
		@include media('768', min) {
			grid-area: agree;
		}
	}

	// .feedback-form__required

	&__required {
		@include media('768', min) {
			grid-area: required;
		}

		span {
            color: var(--red);
        }
	}

    &__textarea {
        @include media('768', min) {
            grid-column: span 2;
        }
    }

	&__btn {
		padding: 8px 39px;
		
		@include media('768', min) {
			grid-area: btn;
		}
	}
}
