.not-found-block {
    padding-bottom: 150px;

    @include media('1100', max) {
        padding-bottom: 100px;
    }

    @include media('767', max) {
        padding-bottom: 50px;
    }

    @include media('500', max) {
        padding-bottom: 30px;
    }

    &__inner {
        @include flex-column;

        @include media('1100', max) {
            align-items: center;
        }
        
        @include media('1101', min) {
            max-width: 972px;
            margin: 0 auto;
        }
    }

    &__content {
        @include flex-column;
        max-width: 739px;

        @include media('1100', max) {
            align-items: center;
            text-align: center;
        }

        @include media('1101', min) {
            margin-top: -83px;
            margin-left: 88px;
        }
    }

    &__title {
        margin-bottom: 17px;
        color: var(--primary);
    }

    &__wrap {
        @include flex-center-between;
        gap: 10px;

        @include media('1100', max) {
            flex-direction: column;
            align-items: center;
        }
    }

    &__subtitle {
        color: var(--black);
    }
}
