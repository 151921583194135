.video-item {
    position: relative;
    aspect-ratio: 184 / 120;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
