.catalog-category {
    $this: &;

    &__top {
        margin-bottom: 20px;
        @include flex-center-y;
        gap: 12px;
    }

    &__img {
        flex: 0 0 120px;
        width: 120px;
        height: 117px;
    }

    &__title {
        font-family: var(--secondary-font);
        font-weight: 600;
        text-transform: uppercase;
        color: var(--primary);
    }

    &__link {
        line-height: 1.9;
        color: var(--black);
        transition: var(--transition) color;

        @media (any-hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }

    &:not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid #d9d9d9;
    }
}
