.price-box {
  padding: 20px 36px 30px;
  border-radius: var(--radius-2);
  background-color: #F1EEE8;

  &__prices {
    margin-bottom: 18px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;
  }

  &__old-price {
    font-family: var(--secondary-font);
    font-size: 20px;
    font-weight: 600;
    color: #544F45;

    @include media('500', max) {
      font-size: 16px;
    }
  }

  &__btns {
    display: flex;
    gap: 9px;
  }

  &__btn {
    min-width: 168px;
  }
}
