.product-info {
    padding-top: 100px;

    @include media('1100', max) {
        padding-top: 50px;
    }

    @include media('767', max) {
        padding-top: 30px;
    }

    &__tabs {
        margin-bottom: 18px;
    }

    p {
        font-size: 20px;
        font-weight: 400;
        color: var(--black);

        @include media('767', max) {
            font-size: 14px;
        }
    }
}
