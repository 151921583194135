.badge {
    border-radius: 2px;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);

    &--preorder {
        background-color: var(--gray);
    }

    &--new {
        background-color: var(--secondary);
    }

    &--hit {
        background-color: #FFB800;
    }

    &--sale {
        background-color: var(--red);
    }
}