.input {
	@include flex-center-y;
	@include padding-x(10px);
	width: 100%;
	height: 42px;
	background-color: var(--light);
	border: 1px solid var(--primary);
	border-radius: var(--radius);
    line-height: 1.96;

	&::placeholder {
		color: #ABABAB;
	}

	&:focus {
		border-color: var(--secondary);
	}
}

textarea {
    @include padding-y(7px);
    min-height: 116px;
}
