.news-article {
    $this: &;

    --imageWidth: 225px;
    @include row-grid(15px);

    @include media('768', min) {
        grid-template-columns: var(--imageWidth) 1fr;
        grid-template-rows: max-content 1fr;
        grid-template-areas:
        'img title'
        'img text'
        ;
        gap: 5px 25px;
    }

    @include media('1101', min) {
        --imageWidth: 368px;
    }

    &__img-wrap {
        @include media('768', min) {
            grid-area: img;
        }
    }

    &__img {
        display: block;
        aspect-ratio: 184 / 120;
    }

    &__title {
        font-family: var(--secondary-font);
        font-size: 20px;
        font-weight: 600;
        color: var(--text-color-primary);
        transition: var(--transition) color;

        @include media('768', min) {
            grid-area: title;
        }

        @media (any-hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }

    &__text {
        @include media('768', min) {
            grid-area: text;
        }

        @include media('1100', max) {
            font-size: 14px;
        }
    }
}
