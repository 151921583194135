.product-gallery {
    &-slider {
        width: 100%;

        .swiper-slide {
            box-sizing: border-box;
        }

        &__img {
            display: block;
            aspect-ratio: 1 / 1;
        }
    }

    &-thumbs {
        .swiper-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
            transform: none !important;
        }

        &__img {
            display: block;
            aspect-ratio: 1 / 1;
        }
    }
}
