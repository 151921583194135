:root {
	--white: #fff;
	--black: #000;
	--light: #F2F2F2;
	--primary: #987C46;
	--secondary: #88CE88;
	--brown: #DED9D2;
	--gray: #bbb;
	--section-bg: #CAC5BD;
	--text-color: #46433C;
	--text-color-primary: #8F836C;
	--red: #f00;

    --container-width: 1170px;
	--container-padding: 15px;

    --gap: 30px;

	--cardWidth: 269px;

	--icon-size: 24px;

	--main-font: 'Montserrat', sans-serif;
	--secondary-font: 'Orchidea Pro', sans-serif;
	--color-default: var(--text-color);

	--transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
	--duration300ms: 0.3s;
	--delay: var(--duration300ms);
	--transition: var(--duration300ms) var(--transition-timing);

	--radius: 3px;
	--radius-2: 4px;

	@include media('767', max) {
		--cardWidth: 220px;
		--gap: 15px;
	}

	@include media('500', max) {
		--icon-size: 20px;
	}
}