.leader {
    @include flex-column;
    gap: 10px;

    &__item {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-size: 14px;
        line-height: 2;
        color: var(--black);
    }

    &__property {
        position: relative;
        display: flex;
        align-items: center;
        font-weight: 600;

        &::before {
            content: '';
            position: absolute;
            bottom: 7px;
            left: 0;
            width: 100%;
            border-bottom: 1px dotted #d9d9d9;
        }

        span {
            position: relative;
            padding-right: 3px;
            background-color: var(--white);
        }
    }

    &__value {
        display: flex;
        align-items: flex-end;
        padding-left: 10px;
    }
}
