.cart-page {
  padding-bottom: 100px;

  @include media('1100', max) {
    padding-bottom: 70px;
  }

  @include media('500', max) {
    padding-bottom: 30px;
  }

  & &__top {
    padding: 54px 0 4px;
  }

  &__grid {
    display: grid;
    gap: 70px 40px;

    @include media('1101', min) {
      grid-template-columns: 1fr 280px; 
      grid-template-areas:
      'table total'
      'checkout total'
      ;
      align-items: flex-start;
    }
  }

  &__table {
    @include media('1101', min) {
      grid-area: table;
    }
  }

  &__total {
    @include media('1101', min) {
      grid-area: total;
    }

    @include media('1100', max) {
      margin-top: -40px;
    }
  }

  &__checkout {
    @include media('1101', min) {
      grid-area: checkout;
    }
  }
}

.cart-table {
  &__thead,
  &__tr {
    display: grid;
    grid-template-columns: 88px calc(258 / 847 * 100%) repeat(3, minmax(max-content, 1fr)) max-content;
    grid-template-areas: 'img info price quantity total remove';
    
    @include media('767', max) {
      grid-template-columns: auto 1fr max-content;
      grid-template-areas:
      'img info remove'
      'img price remove'
      'img quantity remove'
      'img total remove'
      ;
      gap: 11px 19px;
    }
  }

  &__thead {
    padding-bottom: 11px;
    border-bottom: 1px solid var(--brown);

    @include media('767', max) {
      display: none;
    }
  }

  &__tbody {
    padding-top: 13px;
  }

  &__tr {
    &:not(:last-child) {
      margin-bottom: 52px;

      @include media('767', max) {
        margin-bottom: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid var(--brown);
      }
    }

    &:not(:first-child) {
      @include media('767', max) {
        padding-top: 20px;
      }
    }
  }

  &__td {
    @include flex-center-y;
    
    @include media('767', max) {
      justify-content: space-between;
    }

    @include media('768', min) {
      @include padding-x(10px);
    }

    @include media('1101', min) {
      @include padding-x(20px);
    }

    &:first-child {
     @include padding-x(0);
    }

    &:last-child {
     padding-right: 0;
     margin-right: -5px;
    }

    span {
      &:first-child {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
        color: #9A8E80;

       @include media('768', min) {
        display: none;
       }
      }
    }
  }

  &__picture {
    grid-area: img;
  }

  &__img {
    width: 88px;
    height: 88px;

    @media (max-width: 360px) {
      width: 70px;
      height: 70px;
    }
  }

  &__picture,
  &__remove {
    @include media('767', max) {
      align-self: start;
    }
  }

  &__info {
    flex-direction: column;
    align-items: flex-start;
    grid-area: info;
  }

  &__title {
    font-family: var(--secondary-font);
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
    color: var(--primary);

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  &__descr {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 500;
    color: var(--text-color-primary);
  }
  
  &__price {
    grid-area: price;
  }

  &__quantity {
    grid-area: quantity;
  }

  &__total {
    grid-area: total;
  }

  &__remove {
    grid-area: remove;
  }

  &__remove-btn {
    @include flex-center-y;
    border-radius: 50%;
    padding: 5px;
    color: var(--primary);

    .icon {
      fill: currentColor;
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: var(--light);
      }
    }
  }
}
