.about {
    background-color: var(--brown);

    @include media('1101', min) {
        padding: 160px 0 132px;
    }

    &__grid {
        padding: 0 60px 146px;
        display: grid;
        gap: var(--gap) 50px;

        @include media('1100', max) {
            @include padding-x(var(--container-padding));
            padding-bottom: 50px;
        }

        @include media('1101', min) {
            grid-template-columns: 1fr 330px 1fr;
            grid-template-areas: 'pic-1 content pic-2';
            align-items: center;
        }
    }

    &__slider {
        width: 100%;

        &--1 {
            @include media('1101', min) {
                grid-area: pic-1;
            }
        }

        &--2 {
            @include media('1101', min) {
                grid-area: pic-2;
            }
        }

        @media (any-hover: hover) {
            &:hover {
                .slider-btns {
                    @include show();
                }
            }
        }
    }

    &__content {
        @include flex-column-center;
        gap: 45px;
        text-align: center;

        @include media('1100', max) {
            gap: 30px;
        }
        
        @include media('1101', min) {
            grid-area: content;
        }
    }
}

.brands {
    &__title {
        margin-bottom: 11px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }

    &__item {
        @include flex-center;
        flex: 0 0 132px;

        @include media('500', max) {
            flex-basis: calc(50% - var(--gap) / 2);
        }
    }
}