.catalog-card {
    $this: &;

    position: relative;
    @include flex-column;
    height: 100%;

    &__slider {
        @media (any-hover: hover) {
            &:hover {
                .slider-btns {
                    @include show();
                }
            }
        }
    }

    &__badges {
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 10;
        gap: 5px 10px;
    }

    &__img-wrap {
        @include flex-center;
        background-color: var(--brown);
        aspect-ratio: 1 / 1;
    }

    &__img {
        @include flex-center;
        width: 100%;
        height: 100%;
    }

    &__content {
        flex-grow: 1;
        @include flex-column;
        gap: 15px;
        padding: 32px 0 0;

        @include media('500', max) {
            padding-top: 16px;
        }
    }

    &__title {
        flex-grow: 1;
        font-family: var(--secondary-font);
        font-size: 20px;
        line-height: 1.4;
        font-weight: 600;
        color: var(--primary);
        transition: var(--transition) color;

        @include media('500', max) {
            font-size: 14px;
        }

        &:first-child:last-child {
            max-width: 242px;
            margin: 0 auto;
            text-align: center;
            line-height: 1.96;
            text-transform: uppercase;
        }

        @media (any-hover: hover) {
            &:hover {
                color: var(--secondary);
            }
        }
    }

    &__descr {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 500;
        color: var(--text-color-primary);

        @include media('500', max) {
            font-size: 12px;
        }
    }

    &__prices {
        position: relative;
    }

    &__old-price {
        position: absolute;
        top: -12px;
        left: 0;
        font-size: 14px;
        line-height: 1.4;
        text-decoration-line: strikethrough;
        color: var(--text-color-primary);
    }

    &__footer {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;

        @include media('500', max) {
            grid-template-columns: repeat(2, 1fr);
            gap: 5px;
        }
    }

    & &__counter {
        @include media('500', max) {
            width: 100%;
        }
    }

    &__btn {
        flex-grow: 1;

        .icon {
            @include media('501', min) {
                display: none;
            }
        }

        .btn__text {
            @include media('500', max) {
                display: none;
            }
        }
    }
}

.catalog-page {
    &__top {
        margin: 20px 0 55px;
    }

    &__inner {
        display: grid;
        gap: 32px;
        
        @include media('1101', min) {
            grid-template-columns: 270px 1fr;
        }
    }

    &__list {
        --cardWidth: 269px;

        @include media('1100', max) {
            max-width: calc(100vw - var(--container-padding) * 2);
        }
    }

    & &__pagination {
        padding: 66px 0 0;
    }
}

.price {
    font-family: var(--secondary-font);
    font-size: 32px;
    line-height: 1.4;
    font-weight: 600;
    color: var(--primary);

    @include media('1100', max) {
        font-size: 24px;
    }

    @include media('500', max) {
        font-size: 20px;
    }
}