.product-card {
    padding-bottom: 50px;

    & &__head {
        @include padding-y(54px);

        @include media('767', max) {
            @include padding-y(30px);
        }
    }

    &__inner {
        @include flex-column;
        gap: var(--gap);

        @include media('1101', min) {
            flex-direction: row;

            >* {
                width: calc(50% - var(--gap) / 2);
            }
        }
    }

    &__gallery {
        position: relative;
        @include flex-column;
        gap: 25px;
    }

    &__badges {
        position: absolute;
        top: 13px;
        left: 13px;
        z-index: 10;
    }

    &__badge {
        @include padding-y(6.5px);
    }
    
    &__content {
        
    }

    &__leader {
        margin-bottom: 50px;
    }

    &__benefits {
        margin-top: 11px;
        display: grid;
        gap: 15px;

        @include media('501', min) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.product-benefit {
    padding: 7px;
    min-height: 74px;
    @include flex-column-center;
    justify-content: center;
    gap: 3px;
    text-align: center;
    border-radius: var(--radius-2);
    border: 1px solid #ECE1D1;

    &__text {
        font-size: 14px;
        color: var(--primary);
    }
}
