.menu-header {
  display: flex;
  flex-wrap: wrap;

  &__link {
    padding: 14px 10px;
    font-family: var(--secondary-font);
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    text-transform: uppercase;
    color: #5F5F5F;

    @media (any-hover: hover) {
      &:hover {
        color: var(--text-color-primary);
      }
    }
  }
}
