.feedback {
    $this: &;

    min-height: 577px;
    background-color: var(--section-bg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &__body {
        max-width: 770px;
        margin: 0 auto;
    }

    &__title {
		margin-bottom: 23px;
	}

    &__subtitle {
        margin-bottom: 15px;
        color: #544F45;
    }

    &--img-right {
        min-height: auto;
        background-color: transparent;
        background-size: 0;

        @include media('1101', min) {
            background-size: contain;
            background-position: 100% -20%;
        }

        #{$this} {
            &__body {
                margin-left: 0;
            }
        }
    }
}
