// Попарное добавление отступов
@mixin margin-x($margin) {
    margin-right: $margin;
    margin-left: $margin;
}

@mixin margin-y($margin) {
    margin-top: $margin;
    margin-bottom: $margin;
}

@mixin padding-x($padding) {
    padding-right: $padding;
    padding-left: $padding;
}

@mixin padding-y($padding) {
    padding-top: $padding;
    padding-bottom: $padding;
}