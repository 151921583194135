.benefit-card {
  @include flex-column-center;
  gap: 15px;
  padding: 20px 24px;
  text-align: center;
  background-color: #EBEAE7;

  @include media('500', max) {
    padding: 12px 6px;
  }

  &__img {
    width: 76px;
    height: 76px;

    @include media('500', max) {
      width: 55px;
      height: 55px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.6;
    color: var(--primary);

    @include media('501', min) {
      text-transform: uppercase;
    }

    @include media('500', max) {
      font-size: 14px;
    }
  }

  &__text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    color: var(--text-color-primary);

    @include media('500', max) {
      font-size: 12px;
    }
  }
}
