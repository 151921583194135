fieldset {
    margin: 0;
    border: none;
}

.aside-filters {
    @include sticky(30px);
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    &__close {
        display: none;
        position: absolute;
        padding: 20px;
        top: 0;
        right: 0;
        font-size: 45px;
        line-height: 54%;
        color: var(--primary);

        @include media('1100', max) {
            display: block;
        }
    }

    &__btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }

    @include media('1100', max) {
        position: fixed;
        top: -150%;
        left: 0;
        z-index: 50;
        padding: 50px var(--container-padding);
        width: 100%;
        height: 100%;
        overflow-y: auto;
        background-color: var(--white);
        transition: var(--transition) top;

        &.active {
            top: 0;

            .aside-filters__close {
                position: fixed;
            }
        }
    }
}

.aside-filter {
    padding-bottom: 18px;
    border-bottom: 1px solid #d9d9d9;

    &__title {
        display: block;
        margin-bottom: 20px;
        font-family: var(--secondary-font);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--primary);
    }

    &__checks {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-height: 310px;
        @include scrollbar();

        .checkbox__text {
            line-height: 1.96;
            text-transform: uppercase;
            color: var(--black);
        }
    }
}

.range-filter {
    &__values {
        margin-bottom: 14px;
        display: grid;
        grid-template-columns: repeat(2, 91px);
        gap: 9px;

        @include media('1100', max) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__input {
        padding: 7px;
        width: 100%;
        border-radius: var(--radius);
        border: 1px solid var(--primary);
        background-color: var(--light);
        font-size: 14px;
        line-height: 1.96;
        text-transform: uppercase;

        &::placeholder {
            color: #BCB8B1;
        }
    }
}

.filter-btn {
    @include media('1101', min) {
        display: none;
    }
}