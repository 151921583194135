$breakpoints: (
    "500": 500px,
	"501": 501px,
	"767": 767px,
	"768": 768px,
	"1100": 1100px,
	"1101": 1101px,
);

@mixin media($breakpoint, $type) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);

		@media (#{$type}-width: $breakpoint-value) {
			@content;
		}
	}

	@else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}
