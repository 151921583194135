@mixin hide() {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

@mixin show() {
	visibility: visible;
	opacity: 1;
	pointer-events: all;
}
