@mixin scrollbar() {
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 11px;
		background-color: var(--light);
		border-radius: 15px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--primary);
		border-radius: 15px;
	}
}
