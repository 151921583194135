.album-card {
    $this: &;

    position: relative;
    display: grid;
    color: var(--white);

    &__img,
    &__content {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
    }

    &__img {
        position: relative;
        display: block;
        aspect-ratio: 184 / 120;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            transition: var(--transition) opacity;
        }
    }

    &__content {
        @include flex-center;
        text-align: center;
        position: relative;
        z-index: 1;
        padding: 15px;
    }

    &__title {
        font-family: var(--secondary-font);
        font-size: 20px;
        line-height: 1.4;
        font-weight: 600;

        @media (any-hover: hover) {
            &:hover {
                color: var(--accent);
            }
        }
    }

    &__views {
        position: absolute;
        padding-left: 37px;
        bottom: 15px;
        left: 15px;
        font-weight: 600;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: var(--icon-size);
            height: var(--icon-size);
            background-image: url(../images/icons/layers.svg);
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    &__list {
        li {
            font-size: 14px;
            line-height: 1.3;
            font-weight: 500;

            span {
                text-decoration: underline;
            }
        }
    }

    @media (any-hover: hover) {
        &:hover {
            #{$this}__img {
                &::after {
                    opacity: 0;  
                }
            }
        }
    }

    &--style {
        &_hover {
            display: block;
            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                top: -8px;
                right: -8px;
                bottom: -8px;
                left: -8px;
                border-radius: var(--radius-2) var(--radius-2) 0 0;
                background-color: var(--primary);
                box-shadow: 0 7px 10.5px 0 rgba(0, 0, 0, 0.1);
                opacity: 0;
                transition: var(--transition) opacity;
            }

            #{$this} {
                &__content {
                    padding: 10px;
                    align-items: flex-start;
                    justify-content: flex-start;
                    text-align: left;
                    border-radius: 0 0 var(--radius-2) var(--radius-2);
                    box-shadow: 0 7px 10.5px 0 rgba(0, 0, 0, 0.1);
                    background-color: var(--primary);
                    color: var(--white);

                    @include media('1101', min) {
                        position: absolute;
                        top: 100%;
                        left: -8px;
                        right: -8px;
                        opacity: 0;
                        transition: var(--transition) opacity;
                    }
                }

                &__img {
                    &::before {
                        content: '';
                        @include position-center-xy;
                        width: 69px;
                        height: 69px;
                        border-radius: var(--radius-2);
                        border: 1px solid rgba(255, 255, 255, 0.25);
                        background-color: rgba(0, 0, 0, 0.25);
                        background-image: url(../images/icons/eye.svg);
                        background-size: var(--icon-size);
                        background-position: center;
                        background-repeat: no-repeat;
                        z-index: 2;

                        @include media('1101', min) {
                            opacity: 0;
                        }
                    }

                    &::after {
                        @include media('1101', min) {
                            opacity: 0;
                        }
                    }
                }
            }

            @media (any-hover: hover) {
                &:hover {
                    &::before {
                        opacity: 1;
                    }

                    #{$this} {
                        &__content {
                            opacity: 1;
                        }

                        &__img {
                            &::before,
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
