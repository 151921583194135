body {
	min-width: 320px;
	font-style: normal;
	font-family: var(--main-font);
	font-size: 16px;
	line-height: 1.6;
	font-weight: 400;
	color: var(--color-default);
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
	overflow-x: hidden;

	@include media('767', max) {
		font-size: 14px;
	}

	&._lock {
		overflow-y: hidden;
	}
}

a {
	text-decoration: none;
	color: currentColor;
    transition: var(--transition) color;
}

p {
	color: var(--text-color-primary);
    font-weight: 500;

    strong {
        font-weight: 600;
    }
}

.table-wrap {
	@include media('767', max) {
		overflow-x: scroll;
	}
}

.text-content {
	padding-bottom: 90px;

	@include media('1100', max) {
		padding-bottom: 70px;
	}

	@include media('767', max) {
		padding-bottom: 50px;
	}

	@include media('500', max) {
		padding-bottom: 30px;
	}

	ul {
		--iconSize: 32px;

		margin-left: 10px;
		font-size: 20px;

		@include media('767', max) {
			font-size: 16px;
		}

        li {
            position: relative;
            padding-left: calc(var(--iconSize) + 16px);

			&:not(:last-child) {
				margin-bottom: 20px;
			}

            &::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: var(--iconSize);
				height: var(--iconSize);
				background-image: url(../images/icons/check.svg);
				background-position: center;
				background-repeat: no-repeat;
			}
        }
	}

	p {
		font-weight: 400;
		line-height: 1.48;
		color: var(--black);
	}

	p,
	h2,
	h3 {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

	ul,
	ol {
		margin-bottom: 54px;
	}

    h2 {
        font-size: 28px;
		font-weight: 500;
		text-transform: none;

        @include media('767', max) {
            font-size: 20px;
        }
    }

	>picture + * {
		margin-top: 37px;
	}

	.table-wrap + * {
		margin-top: 50px;

		@include media('767', max) {
			margin-top: 30px;
		}
	}

	table {
		width: 100%;
	}

	td {
		padding: 19px;
		width: 25%;
		white-space: nowrap;

		&:first-child {
			@include media('1101', min) {
				padding-left: 56px;
			}
		}
	}

	thead {
		background-color: #8F836C;
		color: var(--white);

		td {
			@include padding-y(14px);
			font-family: var(--secondary-font);
			font-size: 20px;
		}
	}

	tbody {
		td {
			border-bottom: 1px solid #D9D9D9;
		}

		tr {
			&:nth-child(odd) {
				background-color: #F9F9F9;
			}

			&:nth-child(even) {
			 	background-color: #F8FFF8;
			}
		}
	}

    a {
        text-decoration: underline;
        color: var(--primary);

        @media (any-hover: hover) {
            &:hover {
                color: var(--secondary);
            }
        }

		&[href^="tel:"] {
			color: #46433C;
			text-decoration: none;

			@media (any-hover: hover) {
			 	&:hover {
			 		color: var(--primary);
			 	}
			}
		}
    }
}
