.section {
    @include padding-y(30px);

    @include media('768', min) {
        @include padding-y(50px);
    }

    &__top {
        margin-bottom: 16px;
    }

    &__title {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 90px;
            height: 1px;
            background-color: var(--primary);
        }

        @include media('767', max) {
            font-size: 16px;
        }

        &--center {
            text-align: center;
            
            &::after {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__text {
        margin-bottom: 52px;
        max-width: 920px;
    }

    &__btn-wrap {
        margin-top: 35px;
        display: flex;
        justify-content: flex-end;

        @include media('767', max) {
            justify-content: center;
        }
    }

    &__btn {
        padding: 5px 32px;

        b {
            font-weight: 600;
        }
    }

    &--bg {
        background-color: var(--section-bg);
    }
}

.s-benefits {
    @include media('1101', min) {
        padding: 83px 0 97px;
    }

    &__items {
        @include media('767', max) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

.s-catalog {
    @include media('1101', min) {
        padding: 40px 0 56px;
    }
}

.s-popular {
    @include media('1101', min) {
        padding: 104px 0 57px;
    }
}

.s-new {
    @include media('1101', min) {
        padding: 0 0 97px;
    }
}

.s-instagram {
    @include media('1101', min) {
        padding: 67px 0 109px;
    }
}

.s-related {
    @include media('1101', min) {
        padding: 48px 0 75px;
    }
}

.s-feedback {
    @include media('1101', min) {
        padding: 68px 0 58px;

        &.feedback--img-right {
            @include media('1101', min) {
                padding: 95px 0 114px;
            }
        }
    }
}