// input number
input[type='number'] {
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']:hover,
input[type='number']:focus {
	-moz-appearance: number-input;
}

.counter {
  @include flex-center-y;
  width: 91px;
  height: 42px;
  border-radius: var(--radius);
  border: 1px solid var(--primary);
  background-color: var(--light);

  &__btn {
    @include flex-center;
    flex-shrink: 0;
    padding: 4px;
    width: 32px;
    height: 32px;

    .icon {
      stroke: var(--primary);
    }

    @include media('500', max) {
      width: 24px;
      height: 24px;
    }
  }

  &__input {
    width: 100%;
    font-size: 20px;
    line-height: 1.96;
    background-color: var(--light);
    color: var(--primary);
    text-transform: uppercase;
    border: none;
    text-align: center;

    @include media('500', max) {
      font-size: 16px;
    }
  }
}
