.nav-menu {
  &__list {
    @include flex-center-y;
  }

  &__link {
    @include flex-center-y;
    padding: 14px 10px;
    font-family: var(--secondary-font);
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    text-transform: uppercase;

    .icon {
      margin-right: 13px;
      stroke: currentColor;
    }

    @media (any-hover: hover) {
      &:hover {
        color: var(--text-color-primary);
      }
    }
  }
}
