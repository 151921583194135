.tabs {
    display: flex;

    @include media('767', max) {
        overflow-x: auto;
    }
}

.tab {
    position: relative;
    padding: 0 16px 6px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #EBEAE7;
    font-size: 20px;
    line-height: 1.2;
    color: #212121;
    transition: var(--transition) color, var(--transition) border-bottom-color;

    @include media('767', max) {
        font-size: 14px;
        white-space: nowrap;
    }

    &[aria-selected="true"],
    &.active {
        color: var(--primary);
        font-weight: 500;
        border-bottom-color: var(--primary);
    }

    @media (any-hover: hover) {
        &:hover {
            color: var(--primary);
            border-bottom-color: var(--primary);
        }
    }
}

.tabs-content {
    display: none;

    &.active {
        display: block;
    }
}