.page-top {
    padding: 37px 0 20px;

    &__title {
        font-size: 40px;
        color: var(--white);

        @include media('500', max) {
            font-size: 28px;
        }
    }

    &--bg {
        @include padding-y(88px);
        @include bg(cover);

        @include media('767', max) {
            @include padding-y(50px);
        }
    }
}

.title-container {
    @include flex-center-between;
    flex-wrap: wrap;
    gap: 15px;
}