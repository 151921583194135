.container {
	width: calc(var(--container-width) + var(--container-padding) * 2);
	max-width: 100%;
	@include padding-x(var(--container-padding));
	margin: 0 auto;
}

.wrapper {
	position: relative;
	@include flex-column;
	min-height: 100vh;
	overflow-x: hidden;
}

.page {
	position: relative;
	flex: 1 0 auto;

    &--pb {
        padding-bottom: 90px;

        @include media('1100', max) {
            padding-bottom: 70px;
        }

        @include media('767', max) {
            padding-bottom: 50px;
        }

        @include media('500', max) {
            padding-bottom: 30px;
        }
    }
}

.grid-row {
    @include grid-auto-fit(var(--cardWidth), var(--gap));

    /* @include media('767', max) {
        grid-template-columns: repeat(2, 1fr);
    } */

    &--items {
        &_3 {
            --cardWidth: 290px
        }

        &_6 {
            --cardWidth: 178px;
            gap: 15px 20px;

            @include media('500', max) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: 10px;
            }
        }

        &_column {
            grid-template-columns: unset;
            row-gap: 33px;
        }
    }

    &--videos {
        @include media('767', max) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media('500', max) {
            grid-template-columns: 1fr;
        }
    }

    &--cards {
        @include media('767', max) {
            display: flex;
            flex-wrap: wrap;
        }

        >* {
            @include media('767', max) {
                width: calc(50% - var(--gap) / 2);
            }
        }
    }
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--gap);

    @include media('500', max) {
        gap: 15px;
    }

    >* {
        flex: 0 1 var(--cardWidth);
    }
}