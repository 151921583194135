.cart-total {
  padding: 12px 10px 20px;
  border: 1px solid var(--brown);

  &__item {
    @include flex-column;

    &:not(:last-child) {
      margin-bottom: 16px;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--brown);
    }

    &--row {
      flex-direction: row;
      justify-content: space-between;
    }

    &:first-child {
      padding-bottom: 17px;
      margin-bottom: 23px;
    }
  }
  
  &__title,
  &__value {
    font-family: var(--secondary-font);
    font-size: 20px;
    font-weight: 600;
    color: var(--primary);
  }

  &__radio-group {
    margin-top: 13px;
    @include row-grid(12px);
  }

  &__btn {
    margin-top: 11px;
    align-self: end;
  }
}
