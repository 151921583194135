.breadcrumbs {
    --gap: 10px;
    --arrowSize: 16px;

    padding: 12px 0 15px;
    background-color: #EBEAE7;
    
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap);
    }

	&__item {
        font-size: 14px;
        line-height: 1.2;
        color: var(--black);

		&:not(:last-child) {
            position: relative;
            padding-right: calc(var(--arrowSize) + var(--gap));

			&::after {
				content: '';
                @include position-center-y;
                right: 0;
                width: var(--arrowSize);
                height: var(--arrowSize);
                background-image: url(../images/icons/crumb-arrow.svg);
                background-position: center;
                background-repeat: no-repeat;
			}
		}

        &:last-child {
            color: #D0CBC3;
            font-weight: 500;
        }
	}

    &__link {
        transition: var(--transition) color;

        @media (any-hover: hover) {
            &:hover {
                color: var(--primary);
            }
        }
    }
}
