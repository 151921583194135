.search-form {
    @include flex-center-between;
	position: fixed;
	top: -100%;
	left: 0;
	z-index: 20;
	width: 50px;
    height: 50px;
	background-color: var(--white);
    border-bottom: 1px solid var(--black);
	transition: var(--transition) right, var(--transition) top, var(--transition) width, var(--transition) opacity;

    @include media('1100', max) {
        height: 75px;
        border-top: none;
        border-right: none;
        border-left: none;
    }

	&.active {
        top: 0;
        width: 100%;
        height: 95px;
        opacity: 1;
	}

	&__input {
        @include padding-x(15px);
        width: calc(100% - 50px);
		font-size: 20px;
		border: none;

        @include media('1100', max) {
            font-size: 16px;
        }

        @include media('500', max) {
            font-size: 14px;
        }

        &::placeholder {
            color: #C2C2C2;
        }
	}

	&__btn {
        @include position-center-y;
        right: 0;
		width: 50px;
        height: 50px;
        @include flex-center;
        font-size: 50px;
        @include hide();
        transition: var(--transition) all;

        &.active {
            @include show();
        }
        
        .icon {
            stroke: var(--primary);
        }
	}
}
