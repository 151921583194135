.radiobutton {
	$this: &;

    position: relative;
	@include flex-center-y;

	&__input {
		width: 1px;
		height: 1px;
		overflow: hidden;
		position: absolute;
		clip: rect(0 0 0 0);

		&:checked ~ #{$this} {
			&__style {
				&::before {
					@include show();
				}
			}
		}
	}

    &__label {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

	&__style {
		position: relative;
		flex-shrink: 0;
		margin-right: 17px;
		width: var(--icon-size);
		height: var(--icon-size);
		border-radius: 50%;
		border: 1px solid var(--primary);

		&::before {
			content: '';
			@include position-center-xy;
			@include round(10px, var(--primary));
			@include hide();
			transition: var(--transition) all;
		}
	}

	&__text {
		color: var(--black);
        transition: var(--transition) color;
	}

    @media (any-hover: hover) {
        &:hover {
            #{$this}__text {
                color: var(--primary);
            }
        }
    }
}
