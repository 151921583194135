.checkbox {
	$this: &;

    position: relative;
	@include flex-center-y;

	&__input {
		width: 1px;
		height: 1px;
		overflow: hidden;
		position: absolute;
		clip: rect(0 0 0 0);

		&:checked~#{$this} {
			&__style {
				&:before {
					@include show();
				}
			}
		}
	}

    &__label {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

	&__style {
		position: relative;
		flex-shrink: 0;
		margin-right: 13px;
		width: var(--icon-size);
		height: var(--icon-size);
		border-radius: var(--radius);
		border: 1px solid var(--primary);
		background-color: var(--white);
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15) inset;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 14px;
			height: 10px;
			background-image: url(../images/icons/check-mark.svg);
			@include bg(contain);
			@include hide();
			transition: var(--transition) all;
		}
	}

	&__text {
		font-size: 14px;

		a {
            position: relative;
            z-index: 2;
			text-decoration: underline;
			color: var(--primary);
			transition: var(--transition) color;

			@media (any-hover: hover) {
				&:hover {
					color: var(--secondary);
				}
			}
		}
	}
}
