.footer {
	@include padding-y(20px);
    background-color: #9A8E80;
    color: var(--white);

	@include media('768', min) {
		@include padding-y(40px);
	}

	&__grid {
		@include row-grid(25px);

		@include media('1101', min) {
			grid-template-columns: auto 1fr;
			grid-template-rows: repeat(3, max-content);
			grid-template-areas:
			'logo menu'
			'logo middle'
			'logo bottom'
			;
			align-items: center;
			row-gap: 15px;
		}
	}

	&__logo {
		max-width: 245px;
		justify-self: center;
		
		@include media('1101', min) {
			justify-self: start;
			grid-area: logo;
		}
	}

    &__menu {
		@include media('1101', min) {
			grid-area: menu;
		}
    }

	&__middle {
		@include flex-center-y;
		flex-wrap: wrap;
		gap: 20px;

		@include media('1101', min) {
			grid-area: middle;
		}
	}

	&__payment {
		@include flex-center-y;
		flex-wrap: wrap;
		gap: 12px;
	}

	&__payment-text {
		font-weight: 500;
	}

    &__bottom {
        display: flex;
		align-items: flex-start;
		justify-content: space-between;
        flex-wrap: wrap;
        gap: 15px;

		@include media('1101', min) {
			grid-area: bottom;
		}
    }

	// .footer__copyright

	&__copyright {
		max-width: 700px;
		font-size: 14px;
	}

	// .footer__developer

	&__developer {
		@include flex-center-y;
		transition: var(--transition) color;

		@media (any-hover: hover) {
			&:hover {
				color: var(--secondary);
			}
		}
	}

	// .footer__developer-logo

	&__developer-logo {
		flex-shrink: 0;
		margin-right: 8px;
		width: 16px;
		height: 16px;
	}

	// .footer__developer-text

	&__developer-text {
		font-size: 10px;
		line-height: 20px;
		text-decoration: underline;
	}
}

.footer-social {
	@include flex-center-y;
	gap: 25px;

	.icon {
		stroke: var(--white);
		transition: var(--transition) fill, var(--transition) stroke;

		@media (any-hover: hover) {
		 	&:hover {
				stroke: var(--primary);
		 	}
		}

		&.vk {
			fill: var(--white);

			@media (any-hover: hover) {
				&:hover {
					fill: var(--primary);
				}
		   	}
		}
	}
}

.footer-menu {
	display: flex;
	flex-wrap: wrap;
	margin: -10px -15px;

	&__link {
		@include flex-center-y;
		padding: 10px 15px;
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		
		@media (any-hover: hover) {
			&:hover {
				color: var(--secondary);
		 	}
		}
	}
}