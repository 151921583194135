.swiper-button {
    $this: &;

    &-prev,
    &-next {
        @include flex-center;
        position: static;
        margin-top: 0;
        width: 32px;
        height: 32px;
        border-radius: var(--radius-2);
        background-color: var(--primary);
        transition: var(--transition) background-color;

        &::after {
            content: '';
        }

        .icon {
            stroke: var(--white);
        }
        
        @media (any-hover: hover) {
            &:hover {
                background-color: var(--secondary);
            }
        }
    }

    &-prev {
        &#{$this}-disabled {
            background-color: rgba(152, 124, 70, 0.4);
            opacity: 1;
        }
    }
}