.catalog-categories {
  padding: 30px 0 50px;
  border-bottom: 1px solid var(--gray);
}

.category-card {
  @include flex-center-y;
  min-height: 71px;
  padding: 13px 10px;
  background-color: var(--brown);
  color: var(--primary);
  transition: var(--transition) background-color, var(--transition) color;

  &__img {
    flex-shrink: 0;
    margin-right: 13px;
    width: 47px;
    height: 42px;

    @include media('500', max) {
      width: 35px;
      height: 30px;
    }
  }

  &__title {
    font-family: var(--secondary-font);
    font-size: 14px;
    line-height: 1.96;
    font-weight: 600;

    @include media('500', max) {
      font-size: 12px;
    }

    @include media('501', min) {
      text-transform: uppercase;
    }
  }

  &.active {
    background-color: var(--secondary);
    color: var(--white);
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: var(--secondary);
      color: var(--white);
    }
  }
}
