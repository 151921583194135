h {
    &1, &2, &3, &4, &5 {
        font-family: var(--secondary-font);
        line-height: 1.96;
        font-weight: 600;
        color: var(--primary);
    }
}

._h {
    &1, &2, &3, &4, &5 {
        font-family: var(--secondary-font);
        line-height: 1.96;
        font-weight: 600;
        text-transform: uppercase;
        color: var(--primary);
    }
}

h {
    &1, &2, &3, &4 {
        text-transform: uppercase;
    }
}

._h {
    &1, &2, &3, &4 {
        text-transform: uppercase;
    }
}

h2,
._h2 {
    font-size: 32px;

    @include media('500', max) {
        font-size: 24px;
    }
}

h3,
._h3 {
    font-size: 28px;

    @include media('500', max) {
        font-size: 20px;
    }
}

h4,
._h4 {
    font-size: 24px;

    @include media('500', max) {
        font-size: 18px;
    }
}

h5,
._h5 {
    font-size: 20px;

    @include media('500', max) {
        font-size: 16px;
    }
}

h6,
._h6 {
    font-size: 16px;
    color: #46433C;
    font-weight: 500;

    @include media('500', max) {
        font-size: 14px;
    }
}