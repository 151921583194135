.slider-btns {
    @include position-center-y;
    left: 8px;
    right: 8px;
    z-index: 50;
    @include flex-center-between;

    @include media('1101', min) {
        @include hide();
        transition: var(--transition) all;
    }
}