.promo {
    position: relative;
	padding-top: 183px;
	background-position: center bottom;
	background-size: contain;
	background-repeat: no-repeat;

	@include media('1100', max) {
		padding: 120px 0 40%;
		background-size: contain;
	}

	@include media('1101', min) {
		min-height: 985px;
	}

	&__content {
		@include flex-column-center;
		gap: 18px;
		max-width: 715px;
		margin: 0 auto;
		text-align: center;
	}

	&__title {
		font-family: var(--secondary-font);
		font-size: 48px;
		line-height: 1.2;
		font-weight: 400;
		text-transform: uppercase;
		color: var(--primary);

		@include media('1100', max) {
			font-size: 32px;
		}
	}

	&__subtitle {
		max-width: 530px;
		font-size: 20px;
		line-height: 1.2;
		color: #494949;
	}
}
