@mixin position-center-x {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

@mixin position-center-y {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

@mixin position-center-xy {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
