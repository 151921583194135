.pagination {
    padding: 100px 0 95px;
	@include flex-center;
    gap: 25px;

    @include media('1100', max) {
        @include padding-y(50px);
    }

    @include media('767', max) {
        @include padding-y(30px);
        gap: 5px;
    }

	// .pagination__list

	&__list {
		display: flex;
	}

	// .pagination__link

	&__link {
        @include flex-center;
        min-width: 32px;
        height: 32px;
        border-radius: var(--radius);
        color: var(--black);    
        transition: var(--transition) background-color, var(--transition) color;

		&.active {
            background-color: var(--primary);
            color: var(--white);
        }

		&:not(.active):not(span) {
            @media (any-hover: hover) {
                &:hover {
                    background-color: var(--primary);
                    color: var(--white);
                }
            }
        }
	}

    &__btn {
        @include flex-center;
        min-width: 104px;
        padding: 6px;
        gap: 10px;
        border-radius: var(--radius-2);
        border-color: var(--brown);
        color: var(--black);
        text-transform: none;

        svg {
            stroke: var(--primary);
            transition: var(--transition) stroke;
        }

        @include media('500', max) {
            min-width: auto;
            width: 32px;
            height: 32px;
        }

        span {
            @include media('500', max) {
                display: none;
            }
        }

        @media (any-hover: hover) {
            &:hover {
                background-color: transparent;
                border-color: var(--primary);
                color: var(--black);
            }
        }
    }
}
