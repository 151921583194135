.btn {
	$this: &;

	padding: 0;
	cursor: pointer;
	border: none;
	font-family: var(--main-font);
	transition: var(--transition) color, var(--transition) background-color;
	color: currentColor;

	&.disabled,
	&[disabled] {
		background-color: var(--gray);
		border: none;
		cursor: not-allowed;

		@media (any-hover: hover) {
			&:hover {
				background-color: var(--gray);
				color: var(--white);
			}
		}
	}

	svg,
	.icon,
	&__icon {
		flex-shrink: 0;
		transition: var(--transition) stroke;
	}

	&--border {
		border: 1px solid var(--primary);
        border-radius: var(--radius);
        text-transform: uppercase;
		font-weight: 500;
        color: var(--primary);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--primary);
                color: var(--white);
            }
        }
	}

    &--primary {
        background-color: var(--primary);

        @media (any-hover: hover) {
            &:hover {
                background-color: var(--secondary);
            }
        }
    }

    &--secondary {
        background-color: var(--secondary);

        @media (any-hover: hover) {
            &:hover {
                
            }
        }
    }

	&--disabled {
		background-color: var(--gray);

		@media (any-hover: hover) {
			&:hover {
				background-color: var(--primary);
			}
		}
	}

	&--primary,
	&--secondary,
	&--disabled {
		@include flex-center;
		padding: 4px 15px 6px;
		border-radius: var(--radius);
		color: var(--white);
		font-weight: 600;
		line-height: 2;
		text-transform: uppercase;

		svg,
        .icon,
        #{$this}__icon {
            stroke: var(--white);
        }
	}

	&--transparent {
		background-color: transparent;

		@media (any-hover: hover) {
			&:hover {
				background-color: transparent;
			}
		}
	}
}

.btns {
    display: flex;
}