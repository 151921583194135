@charset "UTF-8";
* {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
*:before, *:after {
  box-sizing: inherit;
}

audio,
canvas,
progress,
video {
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

template {
  display: none;
}

html {
  font-family: sans-serif;
  word-break: break-word;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  cursor: default;
}

body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

*:focus,
*:active,
*:hover {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  white-space: pre-wrap;
  tab-size: 4;
}

q {
  quotes: "«" "»";
}

button,
input {
  line-height: normal;
}

button,
input,
select {
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  vertical-align: top;
}

button,
input,
select[multiple],
textarea {
  background-image: none;
}

input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}

input,
textarea {
  resize: none;
  user-select: text;
}

button,
textarea,
select,
input[type=text],
input[type=password],
input[type=search],
input[type=submit],
input[type=button],
input[type=reset],
input[type=range] {
  border-radius: 0;
  appearance: none;
}
button::-ms-clear, button::-ms-reveal,
textarea::-ms-clear,
textarea::-ms-reveal,
select::-ms-clear,
select::-ms-reveal,
input[type=text]::-ms-clear,
input[type=text]::-ms-reveal,
input[type=password]::-ms-clear,
input[type=password]::-ms-reveal,
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal,
input[type=submit]::-ms-clear,
input[type=submit]::-ms-reveal,
input[type=button]::-ms-clear,
input[type=button]::-ms-reveal,
input[type=reset]::-ms-clear,
input[type=reset]::-ms-reveal,
input[type=range]::-ms-clear,
input[type=range]::-ms-reveal {
  display: none;
}

[placeholder]:focus::placeholder {
  color: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

iframe {
  border: none;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
dl,
dd,
dt {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
  border: none;
}
table img {
  max-width: none;
}

address {
  font-style: normal;
}

small {
  font-size: inherit;
}

.no-js-warning {
  position: fixed;
  z-index: 99999;
  width: 100%;
  padding: 15px 20px;
  color: var(--red);
  font-size: 20px;
  background: rgba(255, 0, 0, 0.5);
}

:root {
  --white: #fff;
  --black: #000;
  --light: #F2F2F2;
  --primary: #987C46;
  --secondary: #88CE88;
  --brown: #DED9D2;
  --gray: #bbb;
  --section-bg: #CAC5BD;
  --text-color: #46433C;
  --text-color-primary: #8F836C;
  --red: #f00;
  --container-width: 1170px;
  --container-padding: 15px;
  --gap: 30px;
  --cardWidth: 269px;
  --icon-size: 24px;
  --main-font: "Montserrat", sans-serif;
  --secondary-font: "Orchidea Pro", sans-serif;
  --color-default: var(--text-color);
  --transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
  --duration300ms: 0.3s;
  --delay: var(--duration300ms);
  --transition: var(--duration300ms) var(--transition-timing);
  --radius: 3px;
  --radius-2: 4px;
}
@media (max-width: 767px) {
  :root {
    --cardWidth: 220px;
    --gap: 15px;
  }
}
@media (max-width: 500px) {
  :root {
    --icon-size: 20px;
  }
}

html {
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Regular.woff2") format("woff2"), url("../fonts/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-Medium.woff2") format("woff2"), url("../fonts/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Orchidea Pro";
  src: url("../fonts/OrchideaPro-Regular.woff2") format("woff2"), url("../fonts/OrchideaPro-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Orchidea Pro Md";
  src: url("../fonts/OrchideaPro-Medium.woff2") format("woff2"), url("../fonts/OrchideaPro-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Orchidea Pro SmBd";
  src: url("../fonts/OrchideaPro-SemiBold.woff2") format("woff2"), url("../fonts/OrchideaPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
body {
  min-width: 320px;
  font-style: normal;
  font-family: var(--main-font);
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: var(--color-default);
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  overflow-x: hidden;
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}
body._lock {
  overflow-y: hidden;
}

a {
  text-decoration: none;
  color: currentColor;
  transition: var(--transition) color;
}

p {
  color: var(--text-color-primary);
  font-weight: 500;
}
p strong {
  font-weight: 600;
}

@media (max-width: 767px) {
  .table-wrap {
    overflow-x: scroll;
  }
}

.text-content {
  padding-bottom: 90px;
}
@media (max-width: 1100px) {
  .text-content {
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .text-content {
    padding-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .text-content {
    padding-bottom: 30px;
  }
}
.text-content ul {
  --iconSize: 32px;
  margin-left: 10px;
  font-size: 20px;
}
@media (max-width: 767px) {
  .text-content ul {
    font-size: 16px;
  }
}
.text-content ul li {
  position: relative;
  padding-left: calc(var(--iconSize) + 16px);
}
.text-content ul li:not(:last-child) {
  margin-bottom: 20px;
}
.text-content ul li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--iconSize);
  height: var(--iconSize);
  background-image: url(../images/icons/check.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.text-content p {
  font-weight: 400;
  line-height: 1.48;
  color: var(--black);
}
.text-content p:not(:last-child),
.text-content h2:not(:last-child),
.text-content h3:not(:last-child) {
  margin-bottom: 20px;
}
.text-content ul,
.text-content ol {
  margin-bottom: 54px;
}
.text-content h2 {
  font-size: 28px;
  font-weight: 500;
  text-transform: none;
}
@media (max-width: 767px) {
  .text-content h2 {
    font-size: 20px;
  }
}
.text-content > picture + * {
  margin-top: 37px;
}
.text-content .table-wrap + * {
  margin-top: 50px;
}
@media (max-width: 767px) {
  .text-content .table-wrap + * {
    margin-top: 30px;
  }
}
.text-content table {
  width: 100%;
}
.text-content td {
  padding: 19px;
  width: 25%;
  white-space: nowrap;
}
@media (min-width: 1101px) {
  .text-content td:first-child {
    padding-left: 56px;
  }
}
.text-content thead {
  background-color: #8F836C;
  color: var(--white);
}
.text-content thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: var(--secondary-font);
  font-size: 20px;
}
.text-content tbody td {
  border-bottom: 1px solid #D9D9D9;
}
.text-content tbody tr:nth-child(odd) {
  background-color: #F9F9F9;
}
.text-content tbody tr:nth-child(even) {
  background-color: #F8FFF8;
}
.text-content a {
  text-decoration: underline;
  color: var(--primary);
}
@media (any-hover: hover) {
  .text-content a:hover {
    color: var(--secondary);
  }
}
.text-content a[href^="tel:"] {
  color: #46433C;
  text-decoration: none;
}
@media (any-hover: hover) {
  .text-content a[href^="tel:"]:hover {
    color: var(--primary);
  }
}

.container {
  width: calc(var(--container-width) + var(--container-padding) * 2);
  max-width: 100%;
  padding-right: var(--container-padding);
  padding-left: var(--container-padding);
  margin: 0 auto;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.page {
  position: relative;
  flex: 1 0 auto;
}
.page--pb {
  padding-bottom: 90px;
}
@media (max-width: 1100px) {
  .page--pb {
    padding-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .page--pb {
    padding-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .page--pb {
    padding-bottom: 30px;
  }
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--cardWidth), 1fr));
  gap: var(--gap);
  /* @include media('767', max) {
      grid-template-columns: repeat(2, 1fr);
  } */
}
.grid-row--items_3 {
  --cardWidth: 290px ;
}
.grid-row--items_6 {
  --cardWidth: 178px;
  gap: 15px 20px;
}
@media (max-width: 500px) {
  .grid-row--items_6 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
  }
}
.grid-row--items_column {
  grid-template-columns: unset;
  row-gap: 33px;
}
@media (max-width: 767px) {
  .grid-row--videos {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 500px) {
  .grid-row--videos {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .grid-row--cards {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .grid-row--cards > * {
    width: calc(50% - var(--gap) / 2);
  }
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--gap);
}
@media (max-width: 500px) {
  .flex-row {
    gap: 15px;
  }
}
.flex-row > * {
  flex: 0 1 var(--cardWidth);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.full-link::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.btn {
  padding: 0;
  cursor: pointer;
  border: none;
  font-family: var(--main-font);
  transition: var(--transition) color, var(--transition) background-color;
  color: currentColor;
}
.btn.disabled, .btn[disabled] {
  background-color: var(--gray);
  border: none;
  cursor: not-allowed;
}
@media (any-hover: hover) {
  .btn.disabled:hover, .btn[disabled]:hover {
    background-color: var(--gray);
    color: var(--white);
  }
}
.btn svg,
.btn .icon, .btn__icon {
  flex-shrink: 0;
  transition: var(--transition) stroke;
}
.btn--border {
  border: 1px solid var(--primary);
  border-radius: var(--radius);
  text-transform: uppercase;
  font-weight: 500;
  color: var(--primary);
}
@media (any-hover: hover) {
  .btn--border:hover {
    background-color: var(--primary);
    color: var(--white);
  }
}
.btn--primary {
  background-color: var(--primary);
}
@media (any-hover: hover) {
  .btn--primary:hover {
    background-color: var(--secondary);
  }
}
.btn--secondary {
  background-color: var(--secondary);
}
.btn--disabled {
  background-color: var(--gray);
}
@media (any-hover: hover) {
  .btn--disabled:hover {
    background-color: var(--primary);
  }
}
.btn--primary, .btn--secondary, .btn--disabled {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 15px 6px;
  border-radius: var(--radius);
  color: var(--white);
  font-weight: 600;
  line-height: 2;
  text-transform: uppercase;
}
.btn--primary svg,
.btn--primary .icon,
.btn--primary .btn__icon, .btn--secondary svg,
.btn--secondary .icon,
.btn--secondary .btn__icon, .btn--disabled svg,
.btn--disabled .icon,
.btn--disabled .btn__icon {
  stroke: var(--white);
}
.btn--transparent {
  background-color: transparent;
}
@media (any-hover: hover) {
  .btn--transparent:hover {
    background-color: transparent;
  }
}

.btns {
  display: flex;
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);
}

h1, h2, h3, h4, h5 {
  font-family: var(--secondary-font);
  line-height: 1.96;
  font-weight: 600;
  color: var(--primary);
}

._h1, ._h2, ._h3, ._h4, ._h5 {
  font-family: var(--secondary-font);
  line-height: 1.96;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary);
}

h1, h2, h3, h4 {
  text-transform: uppercase;
}

._h1, ._h2, ._h3, ._h4 {
  text-transform: uppercase;
}

h2,
._h2 {
  font-size: 32px;
}
@media (max-width: 500px) {
  h2,
  ._h2 {
    font-size: 24px;
  }
}

h3,
._h3 {
  font-size: 28px;
}
@media (max-width: 500px) {
  h3,
  ._h3 {
    font-size: 20px;
  }
}

h4,
._h4 {
  font-size: 24px;
}
@media (max-width: 500px) {
  h4,
  ._h4 {
    font-size: 18px;
  }
}

h5,
._h5 {
  font-size: 20px;
}
@media (max-width: 500px) {
  h5,
  ._h5 {
    font-size: 16px;
  }
}

h6,
._h6 {
  font-size: 16px;
  color: #46433C;
  font-weight: 500;
}
@media (max-width: 500px) {
  h6,
  ._h6 {
    font-size: 14px;
  }
}

.swiper-button-prev, .swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
  margin-top: 0;
  width: 32px;
  height: 32px;
  border-radius: var(--radius-2);
  background-color: var(--primary);
  transition: var(--transition) background-color;
}
.swiper-button-prev::after, .swiper-button-next::after {
  content: "";
}
.swiper-button-prev .icon, .swiper-button-next .icon {
  stroke: var(--white);
}
@media (any-hover: hover) {
  .swiper-button-prev:hover, .swiper-button-next:hover {
    background-color: var(--secondary);
  }
}
.swiper-button-prev.swiper-button-disabled {
  background-color: rgba(152, 124, 70, 0.4);
  opacity: 1;
}

.about {
  background-color: var(--brown);
}
@media (min-width: 1101px) {
  .about {
    padding: 160px 0 132px;
  }
}
.about__grid {
  padding: 0 60px 146px;
  display: grid;
  gap: var(--gap) 50px;
}
@media (max-width: 1100px) {
  .about__grid {
    padding-right: var(--container-padding);
    padding-left: var(--container-padding);
    padding-bottom: 50px;
  }
}
@media (min-width: 1101px) {
  .about__grid {
    grid-template-columns: 1fr 330px 1fr;
    grid-template-areas: "pic-1 content pic-2";
    align-items: center;
  }
}
.about__slider {
  width: 100%;
}
@media (min-width: 1101px) {
  .about__slider--1 {
    grid-area: pic-1;
  }
}
@media (min-width: 1101px) {
  .about__slider--2 {
    grid-area: pic-2;
  }
}
@media (any-hover: hover) {
  .about__slider:hover .slider-btns {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}
.about__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 45px;
  text-align: center;
}
@media (max-width: 1100px) {
  .about__content {
    gap: 30px;
  }
}
@media (min-width: 1101px) {
  .about__content {
    grid-area: content;
  }
}

.brands__title {
  margin-bottom: 11px;
}
.brands__list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.brands__item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 132px;
}
@media (max-width: 500px) {
  .brands__item {
    flex-basis: calc(50% - var(--gap) / 2);
  }
}

.album-card {
  position: relative;
  display: grid;
  color: var(--white);
}
.album-card__img, .album-card__content {
  grid-row: 1/2;
  grid-column: 1/2;
}
.album-card__img {
  position: relative;
  display: block;
  aspect-ratio: 184/120;
}
.album-card__img::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: var(--transition) opacity;
}
.album-card__content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 15px;
}
.album-card__title {
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}
@media (any-hover: hover) {
  .album-card__title:hover {
    color: var(--accent);
  }
}
.album-card__views {
  position: absolute;
  padding-left: 37px;
  bottom: 15px;
  left: 15px;
  font-weight: 600;
}
.album-card__views::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: var(--icon-size);
  height: var(--icon-size);
  background-image: url(../images/icons/layers.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.album-card__list li {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
}
.album-card__list li span {
  text-decoration: underline;
}
@media (any-hover: hover) {
  .album-card:hover .album-card__img::after {
    opacity: 0;
  }
}
.album-card--style_hover {
  display: block;
  cursor: pointer;
}
.album-card--style_hover::before {
  content: "";
  position: absolute;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  border-radius: var(--radius-2) var(--radius-2) 0 0;
  background-color: var(--primary);
  box-shadow: 0 7px 10.5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: var(--transition) opacity;
}
.album-card--style_hover .album-card__content {
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: 0 0 var(--radius-2) var(--radius-2);
  box-shadow: 0 7px 10.5px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--primary);
  color: var(--white);
}
@media (min-width: 1101px) {
  .album-card--style_hover .album-card__content {
    position: absolute;
    top: 100%;
    left: -8px;
    right: -8px;
    opacity: 0;
    transition: var(--transition) opacity;
  }
}
.album-card--style_hover .album-card__img::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 69px;
  height: 69px;
  border-radius: var(--radius-2);
  border: 1px solid rgba(255, 255, 255, 0.25);
  background-color: rgba(0, 0, 0, 0.25);
  background-image: url(../images/icons/eye.svg);
  background-size: var(--icon-size);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
}
@media (min-width: 1101px) {
  .album-card--style_hover .album-card__img::before {
    opacity: 0;
  }
}
@media (min-width: 1101px) {
  .album-card--style_hover .album-card__img::after {
    opacity: 0;
  }
}
@media (any-hover: hover) {
  .album-card--style_hover:hover::before {
    opacity: 1;
  }
  .album-card--style_hover:hover .album-card__content {
    opacity: 1;
  }
  .album-card--style_hover:hover .album-card__img::before, .album-card--style_hover:hover .album-card__img::after {
    opacity: 1;
  }
}

fieldset {
  margin: 0;
  border: none;
}

.aside-filters {
  position: sticky;
  top: 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
.aside-filters__close {
  display: none;
  position: absolute;
  padding: 20px;
  top: 0;
  right: 0;
  font-size: 45px;
  line-height: 54%;
  color: var(--primary);
}
@media (max-width: 1100px) {
  .aside-filters__close {
    display: block;
  }
}
.aside-filters__btns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
@media (max-width: 1100px) {
  .aside-filters {
    position: fixed;
    top: -150%;
    left: 0;
    z-index: 50;
    padding: 50px var(--container-padding);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: var(--white);
    transition: var(--transition) top;
  }
  .aside-filters.active {
    top: 0;
  }
  .aside-filters.active .aside-filters__close {
    position: fixed;
  }
}

.aside-filter {
  padding-bottom: 18px;
  border-bottom: 1px solid #d9d9d9;
}
.aside-filter__title {
  display: block;
  margin-bottom: 20px;
  font-family: var(--secondary-font);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary);
}
.aside-filter__checks {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 310px;
  overflow-y: auto;
}
.aside-filter__checks::-webkit-scrollbar {
  width: 11px;
  background-color: var(--light);
  border-radius: 15px;
}
.aside-filter__checks::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 15px;
}
.aside-filter__checks .checkbox__text {
  line-height: 1.96;
  text-transform: uppercase;
  color: var(--black);
}

.range-filter__values {
  margin-bottom: 14px;
  display: grid;
  grid-template-columns: repeat(2, 91px);
  gap: 9px;
}
@media (max-width: 1100px) {
  .range-filter__values {
    grid-template-columns: repeat(2, 1fr);
  }
}
.range-filter__input {
  padding: 7px;
  width: 100%;
  border-radius: var(--radius);
  border: 1px solid var(--primary);
  background-color: var(--light);
  font-size: 14px;
  line-height: 1.96;
  text-transform: uppercase;
}
.range-filter__input::placeholder {
  color: #BCB8B1;
}

@media (min-width: 1101px) {
  .filter-btn {
    display: none;
  }
}

.badge {
  border-radius: 2px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 500;
  color: var(--white);
}
.badge--preorder {
  background-color: var(--gray);
}
.badge--new {
  background-color: var(--secondary);
}
.badge--hit {
  background-color: #FFB800;
}
.badge--sale {
  background-color: var(--red);
}

.benefit-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  padding: 20px 24px;
  text-align: center;
  background-color: #EBEAE7;
}
@media (max-width: 500px) {
  .benefit-card {
    padding: 12px 6px;
  }
}
.benefit-card__img {
  width: 76px;
  height: 76px;
}
@media (max-width: 500px) {
  .benefit-card__img {
    width: 55px;
    height: 55px;
  }
}
.benefit-card__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
  color: var(--primary);
}
@media (min-width: 501px) {
  .benefit-card__title {
    text-transform: uppercase;
  }
}
@media (max-width: 500px) {
  .benefit-card__title {
    font-size: 14px;
  }
}
.benefit-card__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  color: var(--text-color-primary);
}
@media (max-width: 500px) {
  .benefit-card__text {
    font-size: 12px;
  }
}

.breadcrumbs {
  --gap: 10px;
  --arrowSize: 16px;
  padding: 12px 0 15px;
  background-color: #EBEAE7;
}
.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}
.breadcrumbs__item {
  font-size: 14px;
  line-height: 1.2;
  color: var(--black);
}
.breadcrumbs__item:not(:last-child) {
  position: relative;
  padding-right: calc(var(--arrowSize) + var(--gap));
}
.breadcrumbs__item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: var(--arrowSize);
  height: var(--arrowSize);
  background-image: url(../images/icons/crumb-arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.breadcrumbs__item:last-child {
  color: #D0CBC3;
  font-weight: 500;
}
.breadcrumbs__link {
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .breadcrumbs__link:hover {
    color: var(--primary);
  }
}

.burger-menu {
  display: none;
}
@media (max-width: 1100px) {
  .burger-menu {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 102;
    width: 100%;
    height: 100%;
    padding: 100px var(--container-padding) 50px;
    display: flex;
    flex-direction: column;
    background-color: var(--primary);
    color: var(--white);
    transition: var(--transition) left;
    overflow-y: auto;
  }
}
.burger-menu__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
  background-color: transparent;
  border: none;
  font-size: 45px;
  line-height: 57%;
  color: var(--white);
}
.burger-menu__nav .nav-menu__list {
  flex-direction: column;
}
.burger-menu__nav .nav-menu__link {
  font-size: 24px;
}
@media (max-width: 767px) {
  .burger-menu__nav .nav-menu__link {
    font-size: 20px;
  }
}
.burger-menu__menu {
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
}
.burger-menu__menu .menu-header__link {
  display: flex;
  font-size: 24px;
  font-weight: 600;
  color: var(--white);
}
@media (max-width: 767px) {
  .burger-menu__menu .menu-header__link {
    font-size: 20px;
  }
}
@media (max-width: 1100px) {
  .burger-menu.active {
    left: 0;
  }
  .burger-menu.active .burger-menu__close {
    position: fixed;
  }
}

@media (min-width: 1101px) {
  .cart-checkout {
    max-width: 762px;
  }
}

.cart-page {
  padding-bottom: 100px;
}
@media (max-width: 1100px) {
  .cart-page {
    padding-bottom: 70px;
  }
}
@media (max-width: 500px) {
  .cart-page {
    padding-bottom: 30px;
  }
}
.cart-page .cart-page__top {
  padding: 54px 0 4px;
}
.cart-page__grid {
  display: grid;
  gap: 70px 40px;
}
@media (min-width: 1101px) {
  .cart-page__grid {
    grid-template-columns: 1fr 280px;
    grid-template-areas: "table total" "checkout total";
    align-items: flex-start;
  }
}
@media (min-width: 1101px) {
  .cart-page__table {
    grid-area: table;
  }
}
@media (min-width: 1101px) {
  .cart-page__total {
    grid-area: total;
  }
}
@media (max-width: 1100px) {
  .cart-page__total {
    margin-top: -40px;
  }
}
@media (min-width: 1101px) {
  .cart-page__checkout {
    grid-area: checkout;
  }
}

.cart-table__thead, .cart-table__tr {
  display: grid;
  grid-template-columns: 88px 30.4604486423% repeat(3, minmax(max-content, 1fr)) max-content;
  grid-template-areas: "img info price quantity total remove";
}
@media (max-width: 767px) {
  .cart-table__thead, .cart-table__tr {
    grid-template-columns: auto 1fr max-content;
    grid-template-areas: "img info remove" "img price remove" "img quantity remove" "img total remove";
    gap: 11px 19px;
  }
}
.cart-table__thead {
  padding-bottom: 11px;
  border-bottom: 1px solid var(--brown);
}
@media (max-width: 767px) {
  .cart-table__thead {
    display: none;
  }
}
.cart-table__tbody {
  padding-top: 13px;
}
.cart-table__tr:not(:last-child) {
  margin-bottom: 52px;
}
@media (max-width: 767px) {
  .cart-table__tr:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--brown);
  }
}
@media (max-width: 767px) {
  .cart-table__tr:not(:first-child) {
    padding-top: 20px;
  }
}
.cart-table__td {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .cart-table__td {
    justify-content: space-between;
  }
}
@media (min-width: 768px) {
  .cart-table__td {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (min-width: 1101px) {
  .cart-table__td {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.cart-table__td:first-child {
  padding-right: 0;
  padding-left: 0;
}
.cart-table__td:last-child {
  padding-right: 0;
  margin-right: -5px;
}
.cart-table__td span:first-child {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #9A8E80;
}
@media (min-width: 768px) {
  .cart-table__td span:first-child {
    display: none;
  }
}
.cart-table__picture {
  grid-area: img;
}
.cart-table__img {
  width: 88px;
  height: 88px;
}
@media (max-width: 360px) {
  .cart-table__img {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 767px) {
  .cart-table__picture, .cart-table__remove {
    align-self: start;
  }
}
.cart-table__info {
  flex-direction: column;
  align-items: flex-start;
  grid-area: info;
}
.cart-table__title {
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--primary);
}
@media (max-width: 360px) {
  .cart-table__title {
    font-size: 16px;
  }
}
.cart-table__descr {
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: var(--text-color-primary);
}
.cart-table__price {
  grid-area: price;
}
.cart-table__quantity {
  grid-area: quantity;
}
.cart-table__total {
  grid-area: total;
}
.cart-table__remove {
  grid-area: remove;
}
.cart-table__remove-btn {
  display: flex;
  align-items: center;
  border-radius: 50%;
  padding: 5px;
  color: var(--primary);
}
.cart-table__remove-btn .icon {
  fill: currentColor;
}
@media (any-hover: hover) {
  .cart-table__remove-btn:hover {
    background-color: var(--light);
  }
}

.cart-total {
  padding: 12px 10px 20px;
  border: 1px solid var(--brown);
}
.cart-total__item {
  display: flex;
  flex-direction: column;
}
.cart-total__item:not(:last-child) {
  margin-bottom: 16px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--brown);
}
.cart-total__item--row {
  flex-direction: row;
  justify-content: space-between;
}
.cart-total__item:first-child {
  padding-bottom: 17px;
  margin-bottom: 23px;
}
.cart-total__title, .cart-total__value {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
}
.cart-total__radio-group {
  margin-top: 13px;
  display: grid;
  row-gap: 12px;
}
.cart-total__btn {
  margin-top: 11px;
  align-self: end;
}

.catalog-card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (any-hover: hover) {
  .catalog-card__slider:hover .slider-btns {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  }
}
.catalog-card__badges {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 10;
  gap: 5px 10px;
}
.catalog-card__img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brown);
  aspect-ratio: 1/1;
}
.catalog-card__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.catalog-card__content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 32px 0 0;
}
@media (max-width: 500px) {
  .catalog-card__content {
    padding-top: 16px;
  }
}
.catalog-card__title {
  flex-grow: 1;
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--primary);
  transition: var(--transition) color;
}
@media (max-width: 500px) {
  .catalog-card__title {
    font-size: 14px;
  }
}
.catalog-card__title:first-child:last-child {
  max-width: 242px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.96;
  text-transform: uppercase;
}
@media (any-hover: hover) {
  .catalog-card__title:hover {
    color: var(--secondary);
  }
}
.catalog-card__descr {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--text-color-primary);
}
@media (max-width: 500px) {
  .catalog-card__descr {
    font-size: 12px;
  }
}
.catalog-card__prices {
  position: relative;
}
.catalog-card__old-price {
  position: absolute;
  top: -12px;
  left: 0;
  font-size: 14px;
  line-height: 1.4;
  text-decoration-line: strikethrough;
  color: var(--text-color-primary);
}
.catalog-card__footer {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
}
@media (max-width: 500px) {
  .catalog-card__footer {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }
}
@media (max-width: 500px) {
  .catalog-card .catalog-card__counter {
    width: 100%;
  }
}
.catalog-card__btn {
  flex-grow: 1;
}
@media (min-width: 501px) {
  .catalog-card__btn .icon {
    display: none;
  }
}
@media (max-width: 500px) {
  .catalog-card__btn .btn__text {
    display: none;
  }
}

.catalog-page__top {
  margin: 20px 0 55px;
}
.catalog-page__inner {
  display: grid;
  gap: 32px;
}
@media (min-width: 1101px) {
  .catalog-page__inner {
    grid-template-columns: 270px 1fr;
  }
}
.catalog-page__list {
  --cardWidth: 269px;
}
@media (max-width: 1100px) {
  .catalog-page__list {
    max-width: calc(100vw - var(--container-padding) * 2);
  }
}
.catalog-page .catalog-page__pagination {
  padding: 66px 0 0;
}

.price {
  font-family: var(--secondary-font);
  font-size: 32px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--primary);
}
@media (max-width: 1100px) {
  .price {
    font-size: 24px;
  }
}
@media (max-width: 500px) {
  .price {
    font-size: 20px;
  }
}

.catalog-category__top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.catalog-category__img {
  flex: 0 0 120px;
  width: 120px;
  height: 117px;
}
.catalog-category__title {
  font-family: var(--secondary-font);
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary);
}
.catalog-category__link {
  line-height: 1.9;
  color: var(--black);
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .catalog-category__link:hover {
    color: var(--primary);
  }
}
.catalog-category:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}

.catalog-categories {
  padding: 30px 0 50px;
  border-bottom: 1px solid var(--gray);
}

.category-card {
  display: flex;
  align-items: center;
  min-height: 71px;
  padding: 13px 10px;
  background-color: var(--brown);
  color: var(--primary);
  transition: var(--transition) background-color, var(--transition) color;
}
.category-card__img {
  flex-shrink: 0;
  margin-right: 13px;
  width: 47px;
  height: 42px;
}
@media (max-width: 500px) {
  .category-card__img {
    width: 35px;
    height: 30px;
  }
}
.category-card__title {
  font-family: var(--secondary-font);
  font-size: 14px;
  line-height: 1.96;
  font-weight: 600;
}
@media (max-width: 500px) {
  .category-card__title {
    font-size: 12px;
  }
}
@media (min-width: 501px) {
  .category-card__title {
    text-transform: uppercase;
  }
}
.category-card.active {
  background-color: var(--secondary);
  color: var(--white);
}
@media (any-hover: hover) {
  .category-card:hover {
    background-color: var(--secondary);
    color: var(--white);
  }
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
}
.checkbox__input {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}
.checkbox__input:checked ~ .checkbox__style:before {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.checkbox__label {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.checkbox__style {
  position: relative;
  flex-shrink: 0;
  margin-right: 13px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: var(--radius);
  border: 1px solid var(--primary);
  background-color: var(--white);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15) inset;
}
.checkbox__style:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 14px;
  height: 10px;
  background-image: url(../images/icons/check-mark.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition) all;
}
.checkbox__text {
  font-size: 14px;
}
.checkbox__text a {
  position: relative;
  z-index: 2;
  text-decoration: underline;
  color: var(--primary);
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .checkbox__text a:hover {
    color: var(--secondary);
  }
}

.contacts__row {
  display: grid;
  gap: 20px;
}
@media (min-width: 1101px) {
  .contacts__row {
    grid-template-columns: 0.47fr 0.53fr;
  }
}
.contacts__content {
  padding-bottom: 0;
}
.contacts__content p {
  color: #46433C;
}
.contacts__content p b {
  font-weight: 500;
}

@media (max-width: 1100px) {
  .map {
    height: 450px;
  }
}
@media (max-width: 500px) {
  .map {
    height: 300px;
  }
}
@media (min-width: 1101px) {
  .map {
    width: 100%;
    height: 100%;
  }
}
.map > * {
  width: 100% !important;
  height: 100% !important;
}
.map > * > * {
  width: 100% !important;
  height: 100% !important;
}

.ballon-content {
  max-width: 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 25px;
  font-family: var(--main-font);
  color: var(--black);
}
.ballon-content__title {
  font-weight: 600;
}
.ballon-content__address {
  font-weight: 400;
  color: var(--black);
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]:hover,
input[type=number]:focus {
  -moz-appearance: number-input;
}

.counter {
  display: flex;
  align-items: center;
  width: 91px;
  height: 42px;
  border-radius: var(--radius);
  border: 1px solid var(--primary);
  background-color: var(--light);
}
.counter__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  padding: 4px;
  width: 32px;
  height: 32px;
}
.counter__btn .icon {
  stroke: var(--primary);
}
@media (max-width: 500px) {
  .counter__btn {
    width: 24px;
    height: 24px;
  }
}
.counter__input {
  width: 100%;
  font-size: 20px;
  line-height: 1.96;
  background-color: var(--light);
  color: var(--primary);
  text-transform: uppercase;
  border: none;
  text-align: center;
}
@media (max-width: 500px) {
  .counter__input {
    font-size: 16px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.form__fields {
  display: grid;
  gap: var(--gap);
}
@media (max-width: 767px) {
  .form__fields {
    gap: 20px;
  }
}
@media (min-width: 768px) {
  .form__fields {
    grid-template-columns: repeat(2, 1fr);
  }
}
.form__fields--cart {
  gap: 20px;
}
.form__bottom {
  display: grid;
  gap: 15px;
}
@media (min-width: 768px) {
  .form__bottom {
    grid-template-columns: 1fr auto;
    grid-template-areas: "agree btn" "required btn";
    align-items: center;
  }
}
.form__agree, .form__required {
  font-size: 14px;
}
@media (max-width: 500px) {
  .form__agree, .form__required {
    font-size: 12px;
  }
}
@media (min-width: 768px) {
  .form__agree {
    grid-area: agree;
  }
}
@media (min-width: 768px) {
  .form__required {
    grid-area: required;
  }
}
.form__required span {
  color: var(--red);
}
@media (min-width: 768px) {
  .form__textarea {
    grid-column: span 2;
  }
}
.form__btn {
  padding: 8px 39px;
}
@media (min-width: 768px) {
  .form__btn {
    grid-area: btn;
  }
}

.feedback {
  min-height: 577px;
  background-color: var(--section-bg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.feedback__body {
  max-width: 770px;
  margin: 0 auto;
}
.feedback__title {
  margin-bottom: 23px;
}
.feedback__subtitle {
  margin-bottom: 15px;
  color: #544F45;
}
.feedback--img-right {
  min-height: auto;
  background-color: transparent;
  background-size: 0;
}
@media (min-width: 1101px) {
  .feedback--img-right {
    background-size: contain;
    background-position: 100% -20%;
  }
}
.feedback--img-right .feedback__body {
  margin-left: 0;
}

.input {
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  height: 42px;
  background-color: var(--light);
  border: 1px solid var(--primary);
  border-radius: var(--radius);
  line-height: 1.96;
}
.input::placeholder {
  color: #ABABAB;
}
.input:focus {
  border-color: var(--secondary);
}

textarea {
  padding-top: 7px;
  padding-bottom: 7px;
  min-height: 116px;
}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #9A8E80;
  color: var(--white);
}
@media (min-width: 768px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.footer__grid {
  display: grid;
  row-gap: 25px;
}
@media (min-width: 1101px) {
  .footer__grid {
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(3, max-content);
    grid-template-areas: "logo menu" "logo middle" "logo bottom";
    align-items: center;
    row-gap: 15px;
  }
}
.footer__logo {
  max-width: 245px;
  justify-self: center;
}
@media (min-width: 1101px) {
  .footer__logo {
    justify-self: start;
    grid-area: logo;
  }
}
@media (min-width: 1101px) {
  .footer__menu {
    grid-area: menu;
  }
}
.footer__middle {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media (min-width: 1101px) {
  .footer__middle {
    grid-area: middle;
  }
}
.footer__payment {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}
.footer__payment-text {
  font-weight: 500;
}
.footer__bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}
@media (min-width: 1101px) {
  .footer__bottom {
    grid-area: bottom;
  }
}
.footer__copyright {
  max-width: 700px;
  font-size: 14px;
}
.footer__developer {
  display: flex;
  align-items: center;
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .footer__developer:hover {
    color: var(--secondary);
  }
}
.footer__developer-logo {
  flex-shrink: 0;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.footer__developer-text {
  font-size: 10px;
  line-height: 20px;
  text-decoration: underline;
}

.footer-social {
  display: flex;
  align-items: center;
  gap: 25px;
}
.footer-social .icon {
  stroke: var(--white);
  transition: var(--transition) fill, var(--transition) stroke;
}
@media (any-hover: hover) {
  .footer-social .icon:hover {
    stroke: var(--primary);
  }
}
.footer-social .icon.vk {
  fill: var(--white);
}
@media (any-hover: hover) {
  .footer-social .icon.vk:hover {
    fill: var(--primary);
  }
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px;
}
.footer-menu__link {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
@media (any-hover: hover) {
  .footer-menu__link:hover {
    color: var(--secondary);
  }
}

.menu-header {
  display: flex;
  flex-wrap: wrap;
}
.menu-header__link {
  padding: 14px 10px;
  font-family: var(--secondary-font);
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: uppercase;
  color: #5F5F5F;
}
@media (any-hover: hover) {
  .menu-header__link:hover {
    color: var(--text-color-primary);
  }
}

.header--absolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 101;
}
@media (max-width: 1100px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.header__inner {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}
@media (min-width: 1101px) {
  .header__inner {
    grid-template-areas: "logo top" "logo menus";
    grid-template-rows: repeat(2, max-content);
  }
}
@media (max-width: 1100px) {
  .header__logo {
    max-width: 100px;
  }
}
@media (min-width: 1101px) {
  .header__logo {
    grid-area: logo;
  }
}
.header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 40px;
}
@media (max-width: 1100px) {
  .header__top {
    flex-grow: 1;
    justify-content: flex-end;
    column-gap: 20px;
  }
}
@media (min-width: 1101px) {
  .header__top {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--primary);
    grid-area: top;
  }
}
.header__menus {
  grid-area: menus;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1100px) {
  .header .header__nav, .header .header__menu {
    display: none;
  }
}
.header__burger {
  position: relative;
  flex-shrink: 0;
}
.header__schedule {
  position: relative;
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--gray);
}
.header__schedule::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--secondary);
}
@media (max-width: 767px) {
  .header__schedule {
    font-size: 10px;
  }
}
.header__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.2;
  font-weight: 500;
}
@media (min-width: 1101px) {
  .header__contacts {
    margin-left: auto;
  }
}
.header__contacts-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  flex-grow: 1;
}
@media (max-width: 500px) {
  .header__contacts-wrap {
    justify-content: flex-end;
  }
}
.header__phone span {
  font-size: 20px;
}
@media (max-width: 767px) {
  .header__phone span {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  .header__phone span {
    font-size: 12px;
  }
}
@media (max-width: 767px) {
  .header__phone {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .header__phone {
    font-size: 10px;
  }
}
@media (any-hover: hover) {
  .header__phone:hover {
    color: var(--primary);
  }
}
.header__search {
  position: relative;
}
.header__search-btn {
  flex-shrink: 0;
}
.header__actions {
  display: flex;
  align-items: center;
  gap: 25px;
}
.header__action {
  position: relative;
  display: flex;
  align-items: center;
}
.header__action .icon {
  stroke: var(--primary);
}
.header__action span {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 1px 5px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  border-radius: 2px;
  background-color: var(--primary);
  color: var(--white);
}
@media (min-width: 1101px) {
  .header__action.burger-btn {
    display: none;
  }
}

.header-social {
  display: flex;
  align-items: center;
  gap: 15px;
}
.header-social__link {
  display: flex;
  align-items: center;
}
.header-social__icon {
  stroke: var(--primary);
}
@media (min-width: 1101px) {
  .header-social__icon--inst {
    width: 36px;
    height: 36px;
  }
}

.img-cover, .img-contain {
  width: 100%;
  height: 100%;
}
.img-cover {
  object-fit: cover;
}
.img-contain {
  object-fit: contain;
}

.leader {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.leader__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 14px;
  line-height: 2;
  color: var(--black);
}
.leader__property {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.leader__property::before {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 0;
  width: 100%;
  border-bottom: 1px dotted #d9d9d9;
}
.leader__property span {
  position: relative;
  padding-right: 3px;
  background-color: var(--white);
}
.leader__value {
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
}

.logo {
  display: block;
}
.logo__img {
  object-fit: contain;
}

.nav-menu__list {
  display: flex;
  align-items: center;
}
.nav-menu__link {
  display: flex;
  align-items: center;
  padding: 14px 10px;
  font-family: var(--secondary-font);
  font-size: 18px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: uppercase;
}
.nav-menu__link .icon {
  margin-right: 13px;
  stroke: currentColor;
}
@media (any-hover: hover) {
  .nav-menu__link:hover {
    color: var(--text-color-primary);
  }
}

.news-article {
  --imageWidth: 225px;
  display: grid;
  row-gap: 15px;
}
@media (min-width: 768px) {
  .news-article {
    grid-template-columns: var(--imageWidth) 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas: "img title" "img text";
    gap: 5px 25px;
  }
}
@media (min-width: 1101px) {
  .news-article {
    --imageWidth: 368px;
  }
}
@media (min-width: 768px) {
  .news-article__img-wrap {
    grid-area: img;
  }
}
.news-article__img {
  display: block;
  aspect-ratio: 184/120;
}
.news-article__title {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color-primary);
  transition: var(--transition) color;
}
@media (min-width: 768px) {
  .news-article__title {
    grid-area: title;
  }
}
@media (any-hover: hover) {
  .news-article__title:hover {
    color: var(--primary);
  }
}
@media (min-width: 768px) {
  .news-article__text {
    grid-area: text;
  }
}
@media (max-width: 1100px) {
  .news-article__text {
    font-size: 14px;
  }
}

.not-found-block {
  padding-bottom: 150px;
}
@media (max-width: 1100px) {
  .not-found-block {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .not-found-block {
    padding-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .not-found-block {
    padding-bottom: 30px;
  }
}
.not-found-block__inner {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1100px) {
  .not-found-block__inner {
    align-items: center;
  }
}
@media (min-width: 1101px) {
  .not-found-block__inner {
    max-width: 972px;
    margin: 0 auto;
  }
}
.not-found-block__content {
  display: flex;
  flex-direction: column;
  max-width: 739px;
}
@media (max-width: 1100px) {
  .not-found-block__content {
    align-items: center;
    text-align: center;
  }
}
@media (min-width: 1101px) {
  .not-found-block__content {
    margin-top: -83px;
    margin-left: 88px;
  }
}
.not-found-block__title {
  margin-bottom: 17px;
  color: var(--primary);
}
.not-found-block__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
@media (max-width: 1100px) {
  .not-found-block__wrap {
    flex-direction: column;
    align-items: center;
  }
}
.not-found-block__subtitle {
  color: var(--black);
}

.pagination {
  padding: 100px 0 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}
@media (max-width: 1100px) {
  .pagination {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .pagination {
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 5px;
  }
}
.pagination__list {
  display: flex;
}
.pagination__link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 32px;
  height: 32px;
  border-radius: var(--radius);
  color: var(--black);
  transition: var(--transition) background-color, var(--transition) color;
}
.pagination__link.active {
  background-color: var(--primary);
  color: var(--white);
}
@media (any-hover: hover) {
  .pagination__link:not(.active):not(span):hover {
    background-color: var(--primary);
    color: var(--white);
  }
}
.pagination__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 104px;
  padding: 6px;
  gap: 10px;
  border-radius: var(--radius-2);
  border-color: var(--brown);
  color: var(--black);
  text-transform: none;
}
.pagination__btn svg {
  stroke: var(--primary);
  transition: var(--transition) stroke;
}
@media (max-width: 500px) {
  .pagination__btn {
    min-width: auto;
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 500px) {
  .pagination__btn span {
    display: none;
  }
}
@media (any-hover: hover) {
  .pagination__btn:hover {
    background-color: transparent;
    border-color: var(--primary);
    color: var(--black);
  }
}

.price-box {
  padding: 20px 36px 30px;
  border-radius: var(--radius-2);
  background-color: #F1EEE8;
}
.price-box__prices {
  margin-bottom: 18px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
}
.price-box__old-price {
  font-family: var(--secondary-font);
  font-size: 20px;
  font-weight: 600;
  color: #544F45;
}
@media (max-width: 500px) {
  .price-box__old-price {
    font-size: 16px;
  }
}
.price-box__btns {
  display: flex;
  gap: 9px;
}
.price-box__btn {
  min-width: 168px;
}

.product-card {
  padding-bottom: 50px;
}
.product-card .product-card__head {
  padding-top: 54px;
  padding-bottom: 54px;
}
@media (max-width: 767px) {
  .product-card .product-card__head {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.product-card__inner {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}
@media (min-width: 1101px) {
  .product-card__inner {
    flex-direction: row;
  }
  .product-card__inner > * {
    width: calc(50% - var(--gap) / 2);
  }
}
.product-card__gallery {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.product-card__badges {
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 10;
}
.product-card__badge {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}
.product-card__leader {
  margin-bottom: 50px;
}
.product-card__benefits {
  margin-top: 11px;
  display: grid;
  gap: 15px;
}
@media (min-width: 501px) {
  .product-card__benefits {
    grid-template-columns: repeat(3, 1fr);
  }
}

.product-benefit {
  padding: 7px;
  min-height: 74px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
  text-align: center;
  border-radius: var(--radius-2);
  border: 1px solid #ECE1D1;
}
.product-benefit__text {
  font-size: 14px;
  color: var(--primary);
}

.product-gallery-slider {
  width: 100%;
}
.product-gallery-slider .swiper-slide {
  box-sizing: border-box;
}
.product-gallery-slider__img {
  display: block;
  aspect-ratio: 1/1;
}
.product-gallery-thumbs .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  transform: none !important;
}
.product-gallery-thumbs__img {
  display: block;
  aspect-ratio: 1/1;
}

.product-info {
  padding-top: 100px;
}
@media (max-width: 1100px) {
  .product-info {
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .product-info {
    padding-top: 30px;
  }
}
.product-info__tabs {
  margin-bottom: 18px;
}
.product-info p {
  font-size: 20px;
  font-weight: 400;
  color: var(--black);
}
@media (max-width: 767px) {
  .product-info p {
    font-size: 14px;
  }
}

.promo {
  position: relative;
  padding-top: 183px;
  background-position: center bottom;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1100px) {
  .promo {
    padding: 120px 0 40%;
    background-size: contain;
  }
}
@media (min-width: 1101px) {
  .promo {
    min-height: 985px;
  }
}
.promo__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  max-width: 715px;
  margin: 0 auto;
  text-align: center;
}
.promo__title {
  font-family: var(--secondary-font);
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--primary);
}
@media (max-width: 1100px) {
  .promo__title {
    font-size: 32px;
  }
}
.promo__subtitle {
  max-width: 530px;
  font-size: 20px;
  line-height: 1.2;
  color: #494949;
}

.radiobutton {
  position: relative;
  display: flex;
  align-items: center;
}
.radiobutton__input {
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  clip: rect(0 0 0 0);
}
.radiobutton__input:checked ~ .radiobutton__style::before {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.radiobutton__label {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.radiobutton__style {
  position: relative;
  flex-shrink: 0;
  margin-right: 17px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 50%;
  border: 1px solid var(--primary);
}
.radiobutton__style::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition) all;
}
.radiobutton__text {
  color: var(--black);
  transition: var(--transition) color;
}
@media (any-hover: hover) {
  .radiobutton:hover .radiobutton__text {
    color: var(--primary);
  }
}

.search-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: -100%;
  left: 0;
  z-index: 20;
  width: 50px;
  height: 50px;
  background-color: var(--white);
  border-bottom: 1px solid var(--black);
  transition: var(--transition) right, var(--transition) top, var(--transition) width, var(--transition) opacity;
}
@media (max-width: 1100px) {
  .search-form {
    height: 75px;
    border-top: none;
    border-right: none;
    border-left: none;
  }
}
.search-form.active {
  top: 0;
  width: 100%;
  height: 95px;
  opacity: 1;
}
.search-form__input {
  padding-right: 15px;
  padding-left: 15px;
  width: calc(100% - 50px);
  font-size: 20px;
  border: none;
}
@media (max-width: 1100px) {
  .search-form__input {
    font-size: 16px;
  }
}
@media (max-width: 500px) {
  .search-form__input {
    font-size: 14px;
  }
}
.search-form__input::placeholder {
  color: #C2C2C2;
}
.search-form__btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition) all;
}
.search-form__btn.active {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.search-form__btn .icon {
  stroke: var(--primary);
}

.section {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section__top {
  margin-bottom: 16px;
}
.section__title {
  position: relative;
}
.section__title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 1px;
  background-color: var(--primary);
}
@media (max-width: 767px) {
  .section__title {
    font-size: 16px;
  }
}
.section__title--center {
  text-align: center;
}
.section__title--center::after {
  left: 50%;
  transform: translateX(-50%);
}
.section__text {
  margin-bottom: 52px;
  max-width: 920px;
}
.section__btn-wrap {
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 767px) {
  .section__btn-wrap {
    justify-content: center;
  }
}
.section__btn {
  padding: 5px 32px;
}
.section__btn b {
  font-weight: 600;
}
.section--bg {
  background-color: var(--section-bg);
}

@media (min-width: 1101px) {
  .s-benefits {
    padding: 83px 0 97px;
  }
}
@media (max-width: 767px) {
  .s-benefits__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1101px) {
  .s-catalog {
    padding: 40px 0 56px;
  }
}

@media (min-width: 1101px) {
  .s-popular {
    padding: 104px 0 57px;
  }
}

@media (min-width: 1101px) {
  .s-new {
    padding: 0 0 97px;
  }
}

@media (min-width: 1101px) {
  .s-instagram {
    padding: 67px 0 109px;
  }
}

@media (min-width: 1101px) {
  .s-related {
    padding: 48px 0 75px;
  }
}

@media (min-width: 1101px) {
  .s-feedback {
    padding: 68px 0 58px;
  }
}
@media (min-width: 1101px) and (min-width: 1101px) {
  .s-feedback.feedback--img-right {
    padding: 95px 0 114px;
  }
}

.slider-btns {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  right: 8px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 1101px) {
  .slider-btns {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: var(--transition) all;
  }
}

.tabs {
  display: flex;
}
@media (max-width: 767px) {
  .tabs {
    overflow-x: auto;
  }
}

.tab {
  position: relative;
  padding: 0 16px 6px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #EBEAE7;
  font-size: 20px;
  line-height: 1.2;
  color: #212121;
  transition: var(--transition) color, var(--transition) border-bottom-color;
}
@media (max-width: 767px) {
  .tab {
    font-size: 14px;
    white-space: nowrap;
  }
}
.tab[aria-selected=true], .tab.active {
  color: var(--primary);
  font-weight: 500;
  border-bottom-color: var(--primary);
}
@media (any-hover: hover) {
  .tab:hover {
    color: var(--primary);
    border-bottom-color: var(--primary);
  }
}

.tabs-content {
  display: none;
}
.tabs-content.active {
  display: block;
}

.page-top {
  padding: 37px 0 20px;
}
.page-top__title {
  font-size: 40px;
  color: var(--white);
}
@media (max-width: 500px) {
  .page-top__title {
    font-size: 28px;
  }
}
.page-top--bg {
  padding-top: 88px;
  padding-bottom: 88px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .page-top--bg {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.video-item {
  position: relative;
  aspect-ratio: 184/120;
}
.video-item iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}